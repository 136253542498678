<!-- =========================================================================================
  File Name: LessonEdit.vue
  Description: Lesson Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/lesson/pixinvent
========================================================================================== -->

<template>
  <div id="page-lesson-edit">

    <vs-alert :active.sync="lesson_not_found" color="danger" title="Lesson Not Found">
      <span>Lesson record with id: {{ $route.params.lessonId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link class="text-inherit underline" to="/a/lessons">All Lessons</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="lesson_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-settings" icon-pack="feather" label="General">
            <div class="tab-text">
              <lesson-edit-tab-general :data="lesson_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-plus-square" icon-pack="feather" label="Additional">
            <div class="tab-text">
              <lesson-edit-tab-additional :data="lesson_data" class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import LessonEditTabGeneral from './LessonEditTabGeneral.vue';
import LessonEditTabAdditional from './LessonEditTabAdditional.vue';

// Store Module

export default {
  components: {
    LessonEditTabGeneral,
    LessonEditTabAdditional,
  },
  data() {
    return {
      lesson_data: null,
      lesson_not_found: false,
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.fetchLessonData(this.$route.params.lessonId);
    },
  },
  methods: {
    fetchLessonData(lessonId) {

      this.$http.get(`lessons/${lessonId}`)
        .then(response => {

          if (response.data.data) {
            this.lesson_data = response.data.data;
          }

        })
        .catch(error => {

          if (error.response.status === 404) {
            this.lesson_not_found = true;
            return;
          }
        });
    },
  },
  created() {
    this.fetchLessonData(this.$route.params.lessonId);
  },
};

</script>
