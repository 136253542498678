<!-- =========================================================================================
  File Name: LessonEditTabInformation.vue
  Description: Lesson Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/lesson/pixinvent
========================================================================================== -->

<template>
  <div id="lesson-edit-tab-info">
    <!-- Content Row -->

    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="LessonIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">Lesson Additional Information</span>
    </div>

    <div class="vx-row pb-10 pt-8">

      <div class="vx-col w-full">
        <label class="vs-input--label">
          Attached Resources
          &mdash;
          <a href="#" @click="openAttachmentSearch">search by name</a>
        </label>

        <vs-chips v-model="data_local.attachment_ids" placeholder="Add Resource">
          <vs-chip
            v-for="chip in data_local.attachment_ids"
            :key="chip"
            closable
            color="secondary"
            @click="remove(data_local.attachment_ids, chip)">
            {{ chip }}
          </vs-chip>
        </vs-chips>

      </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" class="ml-auto mt-2" @click="initiateLessonUpdate">
            Save Changes
          </vs-button>
          <vs-button class="ml-4 mt-2" color="warning" type="border" @click="resetData">Reset</vs-button>
        </div>
      </div>
    </div>


    <vs-popup :active.sync="attachment_search_active" classContent="popup-example"
              title="Search for Attachment by Name">

      <vs-table
        :data="attachment_search_results"
        :sst="true"
        max-items="5"
        search

        @search="handleAttachmentSearch"
      >

        <template slot="header">
          <h3>Attachments</h3>
        </template>

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Target</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(attach, index) in data" :key="attach.id">

            <vs-td :data="attach.id">
              {{ attach.id }}
            </vs-td>

            <vs-td :data="attach.name">
              {{ attach.name }}
            </vs-td>

            <vs-td :data="attach.target">
              {{ attach.target }}
            </vs-td>

            <vs-td>
              <vs-button color="primary" size="small" type="border" @click="handleAttachmentSelected(attach.id)">
                Select
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      services: [],
      attachment_search_selected: [],
      attachment_search_active: false,
      attachment_search_results: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() {

  },
  methods: {
    openAttachmentSearch() {
      this.attachment_search_active = true;
      return false;
    },
    handleAttachmentSearch(term) {

      if (!term || term.length <= 3) return;

      this.$http.get(`attachments?name=${term}&amount=5`)
        .then(response => {

          if (response.data) {
            this.attachment_search_results = response.data.data;
          }

        });

    },
    handleAttachmentSelected(id) {
      if (!this.data_local.attachment_ids.includes(id)) {
        this.data_local.attachment_ids.push(id);
      }
      this.attachment_search_active = false;
    },

    remove(array, item) {
      array.splice(array.indexOf(item), 1);
    },
    initiateLessonUpdate() {

      const payload = {
        attachment_ids: this.data_local.attachment_ids,
      };

      this.$vs.loading();

      this.$http.post(`lessons/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {
            this.data_local = response.data.data;

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s settings have been updated`,
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          let response;
          if (exception.response) {
            response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            iconPack: 'feather',
            icon: 'icon-lesson-x',
            color: 'danger',
          });
        });
    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style type="text/scss">
.avatar {
  width: 25rem;
}


.con-chips--input {
  border-style: solid !important;
  border-color: $theme-background !important;
  border-width: 1px !important;
  border-radius: 5px;
}
</style>
