<!-- =========================================================================================
  File Name: LessonEditTabInformation.vue
  Description: Lesson Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/lesson/pixinvent
========================================================================================== -->

<template>
  <div id="lesson-edit-tab-info">
    <!-- Content Row -->

    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="LessonIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">Lesson Information</span>
    </div>

    <div class="vx-row pb-10">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input v-model="data_local.name" v-validate="'required'" class="w-full mt-4" label="Lesson Name"
                  name="lesson_name"/>
        <span v-show="errors.has('lesson_name')" class="text-danger text-sm">{{ errors.first('lesson_name') }}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <vs-input v-model="data_local.author_id" v-validate="'required'" class="w-full mt-4" label="Lesson Author"
                  name="lesson_author"/>
        <span v-show="errors.has('lesson_author')" class="text-danger text-sm">{{
            errors.first('lesson_author')
          }}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <vs-input v-model="data_local.video_id" v-validate="'required'" class="w-full mt-4" label="Lesson Video ID"
                  name="lesson_video_id"/>
        &mdash;
        <a href="#" @click="openVideoSearch">search by name</a>
        <span v-show="errors.has('lesson_video_id')" class="text-danger text-sm">{{
            errors.first('lesson_video_id')
          }}</span>
      </div>

      <div class="vx-col w-full mt-4">
        <vs-textarea v-model="data_local.description" class="w-full" height="125px" label="Lesson Description"
                     name="lesson_description"/>
      </div>

      <div class="vx-col w-full mt-4">
        <vs-textarea v-model="data_local.instructions" class="w-full" height="125px" label="Lesson Instructions"
                     name="lesson_instructions"/>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" class="ml-auto mt-2" @click="initiateLessonUpdate">Save Changes
          </vs-button>
          <vs-button class="ml-4 mt-2" color="warning" type="border" @click="resetData">Reset</vs-button>
        </div>
      </div>
    </div>

    <vs-popup :active.sync="video_search_active" classContent="popup-example"
              title="Search for Video by Name">

      <vs-table
        :data="video_search_results"
        :sst="true"
        max-items="5"
        search

        @search="handleVideoSearch"
      >

        <template slot="header">
          <h3>Videos</h3>
        </template>

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Public?</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(attach, index) in data" :key="attach.id">

            <vs-td :data="attach.id">
              {{ attach.id }}
            </vs-td>

            <vs-td :data="attach.name">
              {{ attach.name }}
            </vs-td>

            <vs-td :data="attach.public">
              {{ attach.public ? 'Yes' : 'No' }}
            </vs-td>

            <vs-td>
              <vs-button color="primary" size="small" type="border" @click="handleVideoSelected(attach.id)">
                Select
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      services: [],

      video_search_selected: [],
      video_search_active: false,
      video_search_results: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.data_local.name && this.data_local.author_id &&
        this.data_local.video_id && this.data_local.description;
    },
  },
  mounted() {

  },
  methods: {


    openVideoSearch() {
      this.video_search_active = true;
      return false;
    },
    handleVideoSearch(term) {

      if (!term || term.length <= 3) return;

      this.$http.get(`videos?name=${term}&amount=5`)
        .then(response => {

          if (response.data) {
            this.video_search_results = response.data.data;
          }

        });

    },
    handleVideoSelected(id) {
      this.data_local.video_id = id;
      this.video_search_active = false;
    },


    initiateLessonUpdate() {

      const payload = {
        name: this.data_local.name,
        author_id: this.data_local.author_id,
        video_id: this.data_local.video_id,
        description: this.data_local.description,
        instructions: this.data_local.instructions,
      };

      this.$vs.loading();

      this.$http.post(`lessons/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s settings have been updated`,
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          let response;
          if (exception.response) {
            response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            color: 'danger',
          });
        });
    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style>
.avatar {
  width: 25rem;
}
</style>
